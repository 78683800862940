export const splitList = (data: string[]): string[][] => {
  const length = data.length;
  const maxCols = length <= 10 ? 1 : length > 20 ? 3 : 2;
  const itemsPerCol = Math.ceil(length / maxCols);

  return [...Array(maxCols).keys()].map(
    (elem: number) => {
      const offset = elem * itemsPerCol;
      const end = elem === 0 ? itemsPerCol : (elem * 2) * itemsPerCol;
      return data.slice(offset, end);
    });
};

