

















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class ListOptions extends Vue {
  @Prop({type: Array, required: true}) private options!: string[];
}
