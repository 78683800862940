















import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class MetricChip extends Vue {
  @Prop({type: String, required: true}) private value!: string;
}
