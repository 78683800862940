




















import {Component, Prop, Vue} from 'vue-property-decorator';
import {TreeType} from '@/components/template-builder/detail/enum/TreeType';

@Component({
  components: {
  },
})
export default class NumberOfFieldsChip extends Vue {
  @Prop({type: Number, required: true, default: 0}) private number!: number;
  @Prop({type: String, required: true, default: TreeType.CATEGORY}) private slotType!: TreeType;

  private isCategory = (slotType: TreeType): boolean => {
    return slotType === TreeType.CATEGORY;
  }
}
