var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-treeview',{attrs:{"activatable":"","hoverable":"","open-on-click":"","open":_vm.openNodes,"return-object":"","items":_vm.list},on:{"update:open":function($event){_vm.openNodes=$event}},scopedSlots:_vm._u([{key:"prepend",fn:function(ref){
var item = ref.item;
return [(item.slot === 'category')?[(item.viewType.title === 'IMAGES')?[_c('v-icon',{staticClass:"text-left mr-3"},[_vm._v("mdi-folder-multiple-image")])]:(item.viewType.title === 'GROUP')?[_c('v-icon',{staticClass:"text-left mr-3"},[_vm._v("mdi-folder-multiple-plus")])]:[_c('v-icon',{staticClass:"text-left mr-3"},[_vm._v("mdi-folder-multiple")])]]:(item.slot === 'group')?[(item.viewType.title === 'IMAGES')?[_c('v-icon',{staticClass:"text-left mr-3"},[_vm._v("mdi-folder-image")])]:[_c('v-icon',{staticClass:"text-left mr-3"},[_vm._v("mdi-folder")])]]:(item.slot === 'field')?[_c('v-icon',{staticClass:"text-left mr-3"},[_vm._v("mdi-bookmark")])]:_vm._e()]}},{key:"append",fn:function(ref){
var item = ref.item;
return [_c('TreeViewAction',{attrs:{"item":item},on:{"updateItem":_vm.updateItem},scopedSlots:_vm._u([{key:"prependAction",fn:function(ref){
var itemData = ref.itemData;
return [(!_vm.isFieldSlot(itemData.slot))?_c('NumberOfFieldsChip',{attrs:{"number":itemData.count,"slotType":itemData.slot}}):_vm._e()]}},{key:"tooltipViewType",fn:function(ref){
var itemData = ref.itemData;
var defaultMessage = ref.defaultMessage;
return [(_vm.isFieldSlot(itemData.slot))?_c('FieldInfoTooltip',{attrs:{"item":itemData}}):(itemData.viewType.info)?_c('span',[_vm._v(_vm._s(itemData.viewType.info))]):_c('span',[_vm._v(_vm._s(defaultMessage))])]}},{key:"appendAction",fn:function(ref){
var itemData = ref.itemData;
return [(_vm.isFieldSlot(itemData.slot) && itemData.metric)?_c('MetricChip',{attrs:{"value":itemData.metric}}):_vm._e()]}}],null,true)})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }