import {InternParameter} from '@/components/template-builder/detail/interfaces/InternParameterInterface';
import i18n from '@/store/i18n';
import {InternParameterOption} from '@/components/template-builder/detail/interfaces/InternParameterOptionInterface';

export const attributeInfo = (parameter: InternParameter): string|string[] => {
  if (parameter.options.length > 0) {
    return parameter.options.map((option: InternParameterOption) => option.value ?? '');
  }
  if (parameter.type === 'integer') {
    return i18n.it(
      'tb.attributes.type.integer.info',
      'Accepts round numbers. The user will be presented with a numeric field and keypad with numbers only.',
    ) as string;
  }
  if (parameter.type === 'date') {
    return i18n.it(
      'tb.attributes.type.date.info',
      'Accepts a date. The user will be presented with a datepicker.',
    ) as string;
  }
  if (parameter.type === 'float') {
    return i18n.it(
      'tb.attributes.type.float.info',
      'Accepts numbers with decimals. The user will be presented with a numeric field and keypad that supports numbers and punctuation.',
    ) as string;
  }
  if (parameter.type === 'bool') {
    return i18n.it(
      'tb.attributes.type.bool.info',
      'Accepts true or false. The user will be presented with a choice, true or false, no keyboard required.',
    ) as string;
  }
  if (parameter.type === 'string') {
    return i18n.it(
      'tb.attributes.type.string.info',
      'Accepts text. The user will be presented with a keypad',
    ) as string;
  }
  return i18n.it(
    'tb.attributes.type.custom.info',
    'Custom type',
  ) as string;
};

