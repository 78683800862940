





















import {Component, Prop, Vue} from 'vue-property-decorator';
import {TreeItem} from '@/components/template-builder/detail/interfaces/treeview/TreeItemInterface';
import ListOptions from '@/components/template-builder/detail/tree-components/ListOptions.vue';
import {splitList} from '@/components/template-builder/detail/helpers/ListOptionsHelper';

@Component({
  components: {
    ListOptions,
  },
})
export default class FieldInfoTooltip extends Vue {
  @Prop({type: Object, required: true}) private item!: TreeItem;


  private get splitList(): string[][] {
    const info = this.item.viewType.info;
    if (!info || typeof info === 'string') {
      return [];
    }
    return splitList(info);
  }

}
