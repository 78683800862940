










import {Component, Prop, Vue} from 'vue-property-decorator';

@Component({
  components: {
  },
})
export default class DetailHeader extends Vue {
  @Prop({type: String, required: false}) private title !: string;

}
