


















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {TreeItem} from '@/components/template-builder/detail/interfaces/treeview/TreeItemInterface';
import { ItemAction } from './enum/ItemAction';
import RemoveItem from '@/components/template-builder/detail/tree-components/RemoveItem.vue';

@Component({
  components: {
    RemoveItem,
  },
})
export default class CategoryTreeViewAction extends Vue {
  @Prop({type: Object, required: true}) private item!: TreeItem;

  private initialInfo = {slot: this.item.slot, id: this.item.id, name: this.item.name};

  private editItem() {
    this.$emit('updateItem', {action: ItemAction.EDIT, ...this.initialInfo});
  }

  private removeItem() {
    this.$emit('updateItem', {action: ItemAction.DELETE, ...this.initialInfo});
  }

}
