






















































































import {DetailFlow as DetailFlowType, DetailFlow_entityFlow, DisplayType} from '@/types/auction';
import {Component, Provide, Vue} from 'vue-property-decorator';
import Alert from '@/layouts/back-office/ui-kit/alert/Alert.vue';
import LoadingEllipsis from '@/layouts/back-office/ui-kit/alert/LoadingEllipsis.vue';
import ResizableContainer from '@/layouts/back-office/elements/resize-container/ResizableContainer.vue';
import CategoryList from '@/components/template-builder/detail/CategoryList.vue';
import {totalFields} from '@/components/template-builder/detail/helpers/StatisticsHelper';
import {BuilderInterface} from '@/components/template-builder/detail/interfaces/BuilderInterface';
import {fetchBuilder, hasBuilder} from '@/components/template-builder/detail/helpers/BuilderHelper';
import {ParameterCategory} from '@/components/template-builder/detail/interfaces/ParameterCategoryInterface';
import {UpdateItem} from '@/components/template-builder/detail/interfaces/UpdateItemInterface';
import DetailHeader from '@/components/template-builder/detail/DetailHeader.vue';
import {Meta} from '@sophosoft/vue-meta-decorator';
import TemplateType from '@/components/template-builder/detail/TemplateType.vue';
import {ItemAction} from '@/components/template-builder/detail/enum/ItemAction';
import {TreeType} from '@/components/template-builder/detail/enum/TreeType';

@Component({
  components: {
    DetailHeader,
    CategoryList,
    Alert,
    LoadingEllipsis,
    ResizableContainer,
    TemplateType,
  },
})
export default class DetailTemplate extends Vue {

  @Provide() private templateType: number = 1;

  private displaySidebar: boolean = true;
  private widthSidebar: string = '20%';
  private fixedSidebar: boolean = true;

  private displayParameterList: boolean = false;
  private selectedCategory: ParameterCategory|null = null;
  private name!: string|null;


  private get variables() {
    const displayType = this.$route.query.format ?? DisplayType.APP;
    return {
      id: this.$route.params.id,
      displayType,
    };
  }

  private flowTitle = (data: DetailFlow_entityFlow): string => {
    return `${data.group ? `${data.group.name} - ` : ''} ${data.inspectionType.name} (${this.numberOfTotalFields(data)})`;
  }

  private hasBuilder = (data: DetailFlow_entityFlow): boolean => {
    return hasBuilder(data);
  }

  private fetchBuilder = (data: DetailFlow_entityFlow): BuilderInterface => {
    return fetchBuilder(data);
  }

  private numberOfTotalFields = (data: DetailFlow_entityFlow): number => {
    return totalFields(fetchBuilder(data));
  }

  private updatedItem(args: UpdateItem): void {
    if (args.slot !== TreeType.CATEGORY) {
      return;
    }
    if (args.action === ItemAction.DELETE) {
      return;
    }
    this.name = args.name;
    this.displaySidebar = true;
    this.displayParameterList = true;
  }

  private closeSidebar() {

    this.displayParameterList = false;
  }

  private resolveData = (data: DetailFlowType): DetailFlow_entityFlow => {
    return data.entityFlow;
  }

  private updateTemplateType(value: number) {
    this.templateType = value;
  }

  @Meta
  private getMetaInfo() {
    return {
      title: `${this.$it('tb.detail.title', 'Detail page')} | ${this.$it('tb.title', 'Template Builder')}`,
    };
  }
}
