























































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {BuilderInterface} from '@/components/template-builder/detail/interfaces/BuilderInterface';
import {items} from '@/components/template-builder/detail/helpers/TreeviewHelper';
import TreeViewAction from '@/components/template-builder/detail/TreeViewAction.vue';
import {TreeItem} from './interfaces/treeview/TreeItemInterface';
import {TreeType} from '@/components/template-builder/detail/enum/TreeType';
import FieldInfoTooltip from '@/components/template-builder/detail/tree-components/FieldInfoTooltip.vue';
import MetricChip from '@/components/template-builder/detail/tree-components/MetricChip.vue';
import NumberOfFieldsChip from '@/components/template-builder/detail/tree-components/NumberOfFieldsChip.vue';
import {UpdateItem} from '@/components/template-builder/detail/interfaces/UpdateItemInterface';

@Component({
  components: {
    NumberOfFieldsChip,
    MetricChip,
    FieldInfoTooltip,
    TreeViewAction,
  },
})
export default class CategoryList extends Vue {
  @Prop({type: Object, required: true}) private builder!: BuilderInterface;

  private prevNodes: TreeItem[] = [];
  private openNodes: TreeItem[] = [];

  private get list() {
    return items(this.builder.parameterCategories);
  }

  private updateItem(args: UpdateItem) {
    this.$emit('updateItem', args);
  }
  @Watch('openNodes')
  private watchOpenNodes(): void {

    if (this.prevNodes.length === this.openNodes.length) {
      return;
    }

    if (this.prevNodes.length > this.openNodes.length || this.openNodes.length === 0) {
      return this.updatePrevNode();
    }

    this.openItem(this.firstChildOfLastOpenedNode());
    this.updatePrevNode();
  }

  private updatePrevNode(): void {
    this.prevNodes = this.openNodes;
  }

  private openItem(item: TreeItem|null): void {
    if (!item) {
      return;
    }
    this.openNodes = [...this.openNodes, item];
  }

  private firstChildOfLastOpenedNode(): TreeItem|null {
    const lastNode = this.openNodes.slice().pop();
    if (!lastNode) {
      return null;
    }
    if (!lastNode.children || lastNode.children.length === 0) {
      return null;
    }

    const firstChild = lastNode.children.shift();
    if (!firstChild) {
      return null;
    }
    return firstChild;
  }

  private isFieldSlot = (slotType: TreeType) => {
    return slotType === TreeType.FIELD;
  }

}
