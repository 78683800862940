import {DetailFlow_entityFlow} from '@/types/auction';
import {BuilderInterface} from '@/components/template-builder/detail/interfaces/BuilderInterface';

export const hasBuilder = (data: DetailFlow_entityFlow): boolean => {
  return !!(data.draft || data.published);
};

export const fetchBuilder = (data: DetailFlow_entityFlow): BuilderInterface => {
  if (data.draft) {
    return data.draft;
  }
  return JSON.parse(JSON.stringify(data.published));
};

