import {ParameterCategory} from '@/components/template-builder/detail/interfaces/ParameterCategoryInterface';
import {TreeItem} from '@/components/template-builder/detail/interfaces/treeview/TreeItemInterface';
import {totalFieldsPerCategory, totalFieldsPerGroup} from '@/components/template-builder/detail/helpers/StatisticsHelper';
import {Parameter} from '@/components/template-builder/detail/interfaces/ParameterInterface';
import {ParameterGroup} from '@/components/template-builder/detail/interfaces/ParameterGroupInterface';
import {attributeInfo} from '@/components/template-builder/detail/helpers/AttributeInfo';
import {TreeType} from '@/components/template-builder/detail/enum/TreeType';

export const items = (data: ParameterCategory[]) => {
  if (data.length === 0) {
    return [];
  }

  return data.map((category: ParameterCategory, index: number): TreeItem => {
    const id = `${index}`;
    return {
        id,
        itemId: category.id,
        name: category.name,
        count: totalFieldsPerCategory(category.parameterGroups),
        slot: TreeType.CATEGORY,
        viewType: {
          title: category.viewType,
          info: 'Information about the category',
        },
        children: groupList(category.parameterGroups, id),
      };
    });
};

const groupList = (groups: ParameterGroup[], parentId: string): TreeItem[] => {
  return groups.map((data: ParameterGroup, index: number): TreeItem => {
    const id = `${parentId}-${index}`;
    return {
      id,
      itemId: data.id,
      name: data.name ?? `Screen ${index + 1}`,
      count: totalFieldsPerGroup(data),
      viewType: {
        title: data.viewType,
      },
      children: fieldList(data.parameters, id),
      slot: TreeType.GROUP,
    };
  });
};


const fieldList = (attributes: Parameter[], parentId: string): TreeItem[] => {
  return attributes.map((attribute: Parameter, index: number): TreeItem => {
    const id = `${parentId}-${index}`;
    return {
      id,
      itemId: attribute.id,
      name: attribute.name,
      isRequired: attribute.valueRequired,
      metric: attribute.metric,
      viewType: {
        title: attribute.type,
        info: attributeInfo(attribute.parameter),
      },
      slot: TreeType.FIELD,
      children: null,
    };
  });
};
