
    var doc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"FlowParameterCategory"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"FlowParameterCategory"}},"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"displayType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"viewType"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"order"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"parameterCategory"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"ParameterCategory"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"name"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"parameterGroups"},"arguments":[],"directives":[],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"FragmentSpread","name":{"kind":"Name","value":"FlowParameterGroup"},"directives":[]}]}},{"kind":"Field","name":{"kind":"Name","value":"createdAt"},"arguments":[],"directives":[]},{"kind":"Field","name":{"kind":"Name","value":"updatedAt"},"arguments":[],"directives":[]}]}}],"loc":{"start":0,"end":348}};
    doc.loc.source = {"body":"#import \"@/graphql/fragments/flows/ParameterCategory.gql\"\n#import \"@/graphql/fragments/flows/FlowParameterGroup.gql\"\n\nfragment FlowParameterCategory on FlowParameterCategory {\n  id\n  displayType\n  viewType\n  order\n  parameterCategory {\n    ...ParameterCategory\n  }\n  name\n  parameterGroups {\n    ...FlowParameterGroup\n  }\n  createdAt\n  updatedAt\n}\n","name":"GraphQL request","locationOffset":{"line":1,"column":1}};
  

    var names = {};
    function unique(defs) {
      return defs.filter(
        function(def) {
          if (def.kind !== 'FragmentDefinition') return true;
          var name = def.name.value
          if (names[name]) {
            return false;
          } else {
            names[name] = true;
            return true;
          }
        }
      )
    }
  doc.definitions = doc.definitions.concat(unique(require("@/graphql/fragments/flows/ParameterCategory.gql").definitions));
doc.definitions = doc.definitions.concat(unique(require("@/graphql/fragments/flows/FlowParameterGroup.gql").definitions));


      module.exports = doc;
    
