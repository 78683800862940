
















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {TreeType} from '@/components/template-builder/detail/enum/TreeType';

@Component
export default class RemoveItem extends Vue {
  @Prop({type: String, required: true}) private slotType!: TreeType;
  @Prop({type: String, required: true}) private name!: string | null;

  private dialog: boolean = false;

  private closeDialog() {
    this.dialog = false;
  }

  private removeItem() {
    this.closeDialog();
    this.$emit('confirmRemoval');
  }
}
