import {ParameterGroup} from '@/components/template-builder/detail/interfaces/ParameterGroupInterface';
import {BuilderInterface} from '@/components/template-builder/detail/interfaces/BuilderInterface';
import {ParameterCategory} from '@/components/template-builder/detail/interfaces/ParameterCategoryInterface';

export const totalFields = (builder: BuilderInterface|null) => {
  if (!builder) {
    return 0;
  }
  return builder.parameterCategories.reduce((prev: number, curr: ParameterCategory) => {
    return prev + totalFieldsPerCategory(curr.parameterGroups);
  }, 0);
};

export const totalFieldsPerCategory = (groups: ParameterGroup[]): number => {
  return groups.reduce((prev: number, curr: ParameterGroup) => {
    return prev + totalFieldsPerGroup(curr);
  }, 0);
};

export const totalFieldsPerGroup = (group: ParameterGroup): number => {
  return group.parameters ? group.parameters.length : 0;
};
