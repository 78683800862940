



























import {Component, Inject, Vue, Watch} from 'vue-property-decorator';

@Component
export default class TemplateType extends Vue {
  @Inject('templateType') private value!: number;

  private type: number = this.value;

  // @todo: Fetch template UI types
  private types: Array<{id: number, label: string, type: string}> = [];

  @Watch('type')
  private updateSelected() {
      this.$emit('update:type', this.type);
  }
}
